import React from "react";
import { PhoneIcon, MapPinIcon, EnvelopeIcon } from "@heroicons/react/24/solid";

const Contact = () => {
  return (
    <div className="bg-[#282725] py-14 sm:py-16 md:py-20 px-5">
      <div className="max-w-adsonsWidth mx-auto flex items-center md:flex-row flex-col gap-16">
        <div className="md:w-6/12">
          <div
            className="text-white text-xl sm:text-2xl font-bold uppercase"
            style={{ letterSpacing: "1.5px" }}
          >
            Contact us for your one stop service
          </div>
          <div className="sm:text-lg text-white mt-7">
            We will listen to your requirements and deliver the highest quality
            service from the very first enquiry to the end delivery-guaranteed.
          </div>
          <div className="sm:text-lg text-white">
            Get in touch by calling us on the number or sending and email. We
            look forward to working with you.
          </div>
        </div>
        <div className="md:w-6/12">
          <div className="flex items-center gap-5">
            <div className="hexagon">
              <PhoneIcon className="w-6 h-6 text-white" />
            </div>
            <div className="text-white sm:text-xl">+92 300 866 20 25</div>
          </div>
          <div className="flex items-center gap-5 my-10">
            <div className="hexagon">
              <MapPinIcon className="w-6 h-6 text-white" />
            </div>
            <div className="text-white sm:text-xl">
              193, VIP Block, Canal Park, Faisalabad, Pakistan
            </div>
          </div>
          <div className="flex items-center gap-5">
            <div className="hexagon">
              <EnvelopeIcon className="w-6 h-6 text-white" />
            </div>
            <div className="text-white sm:text-xl">info@adsonsent.com</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

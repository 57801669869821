import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import banner1 from "./../assets/images/Banner1.jpg";
import banner2 from "./../assets/images/Banner2.jpg";
import banner3 from "./../assets/images/Banner3.jpg";
import banner4 from "./../assets/images/Banner4.jpg";
import { ArrowSmallRightIcon } from "@heroicons/react/24/outline";
import { Autoplay } from "swiper/modules";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        <SwiperSlide>
          <div>
            <div className="relative">
              <img
                src={banner1}
                width="100%"
                height="100%"
                className="object-cover -z-10 aspect-square sm:aspect-[2/1]"
                alt="banner1"
              />
              <div
                className="w-full absolute"
                style={{
                  top: "50%",
                  transform: "translate(0%, -50%)",
                }}
              >
                <div className="max-w-adsonsWidth mx-5 md:mx-10 adsonsBreakpoint:mx-auto">
                  <div
                    className="bg-[#0068E5] text-lg sm:text-2xl md:text-4xl text-white max-w-fit px-3 md:px-4 py-2 md:py-3 uppercase font-bold"
                    style={{ letterSpacing: "3.5px" }}
                  >
                    WELCOME TO ADSONS
                  </div>
                  <div className="mt-3 sm:mt-5 bg-gray-600 text-sm sm:text-lg md:text-2xl text-white max-w-fit px-3 md:px-4 py-2 md:py-2.5 uppercase">
                    HIGH-GRADE GARMENTS ACCESSORIES
                  </div>
                  <div
                    className="mt-10 sm:mt-12 md:mt-16 flex items-center cursor-pointer w-fit"
                    onClick={() => handleClickScroll("products")}
                  >
                    <div className="bg-gray-600 px-3 md:px-4 py-1.5 sm:py-2 text-white uppercase text-xs sm:text-sm md:text-lg">
                      Explore
                    </div>
                    <div className="px-3 bg-[#0068E5] py-1.5 md:py-2 text-white">
                      <ArrowSmallRightIcon className="w-4 h-4 sm:w-6 sm:h-6 md:w-7 md:h-7" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <div className="relative">
              <img
                src={banner2}
                width="100%"
                height="100%"
                className="object-cover -z-10 aspect-square sm:aspect-[2/1]"
                alt="Banner 2"
              />
              <div
                className="w-full absolute"
                style={{
                  top: "50%",
                  transform: "translate(0%, -50%)",
                }}
              >
                <div className="max-w-adsonsWidth mx-5 md:mx-10 adsonsBreakpoint:mx-auto">
                  <div
                    className="bg-[#0068E5] text-lg sm:text-2xl md:text-4xl text-white max-w-fit px-3 md:px-4 py-2 md:py-3 uppercase font-bold"
                    style={{ letterSpacing: "3.5px" }}
                  >
                    FINEST QUALITY
                  </div>
                  <div className="mt-3 sm:mt-5 bg-gray-600 text-sm sm:text-lg md:text-2xl text-white max-w-fit px-3 md:px-4 py-2 md:py-2.5 uppercase">
                    TRUSTED BY GLOBAL BRANDS
                  </div>
                  <div
                    className="mt-10 sm:mt-12 md:mt-16 flex items-center cursor-pointer w-fit"
                    onClick={() => handleClickScroll("products")}
                  >
                    <div className="bg-gray-600 px-3 md:px-4 py-1.5 sm:py-2 text-white uppercase text-xs sm:text-sm md:text-lg">
                      Explore
                    </div>
                    <div className="px-3 bg-[#0068E5] py-1.5 md:py-2 text-white">
                      <ArrowSmallRightIcon className="w-4 h-4 sm:w-6 sm:h-6 md:w-7 md:h-7" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <div className="relative">
              <img
                src={banner3}
                width="100%"
                height="100%"
                className="object-cover -z-10 aspect-square sm:aspect-[2/1]"
                alt="Banner 3"
              />
              <div
                className="w-full absolute"
                style={{
                  top: "50%",
                  transform: "translate(0%, -50%)",
                }}
              >
                <div className="max-w-adsonsWidth mx-5 md:mx-10 adsonsBreakpoint:mx-auto">
                  <div
                    className="bg-[#0068E5] text-lg sm:text-2xl md:text-4xl text-white max-w-fit px-3 md:px-4 py-2 md:py-3 uppercase font-bold"
                    style={{ letterSpacing: "3.5px" }}
                  >
                    Long-Term Sustainable Development
                  </div>
                  <div className="mt-3 sm:mt-5 bg-gray-600 text-sm sm:text-lg md:text-2xl text-white max-w-fit px-3 md:px-4 py-2 md:py-2.5 uppercase">
                    Constantly Developing to create better customer value.
                  </div>
                  <div
                    className="mt-10 sm:mt-12 md:mt-16 flex items-center cursor-pointer w-fit"
                    onClick={() => handleClickScroll("products")}
                  >
                    <div className="bg-gray-600 px-3 md:px-4 py-1.5 sm:py-2 text-white uppercase text-xs sm:text-sm md:text-lg">
                      Explore
                    </div>
                    <div className="px-3 bg-[#0068E5] py-1.5 md:py-2 text-white">
                      <ArrowSmallRightIcon className="w-4 h-4 sm:w-6 sm:h-6 md:w-7 md:h-7" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <div className="relative">
              <img
                src={banner4}
                width="100%"
                height="100%"
                className="object-cover -z-10 aspect-square sm:aspect-[2/1]"
                alt="Banner 4"
              />
              <div
                className="w-full absolute"
                style={{
                  top: "50%",
                  transform: "translate(0%, -50%)",
                }}
              >
                <div className="max-w-adsonsWidth mx-5 md:mx-10 adsonsBreakpoint:mx-auto">
                  <div
                    className="bg-[#0068E5] text-lg sm:text-2xl md:text-4xl text-white max-w-fit px-3 md:px-4 py-2 md:py-3 uppercase font-bold"
                    style={{ letterSpacing: "3.5px" }}
                  >
                    Harmonious Innovation
                  </div>
                  <div className="mt-3 sm:mt-5 bg-gray-600 text-sm sm:text-lg md:text-2xl text-white max-w-fit px-3 md:px-4 py-2 md:py-2.5 uppercase">
                    Quality and expertise on it's Best.
                  </div>
                  <div
                    className="mt-10 sm:mt-12 md:mt-16 flex items-center cursor-pointer w-fit"
                    onClick={() => handleClickScroll("products")}
                  >
                    <div className="bg-gray-600 px-3 md:px-4 py-1.5 sm:py-2 text-white uppercase text-xs sm:text-sm md:text-lg">
                      Explore
                    </div>
                    <div className="px-3 bg-[#0068E5] py-1.5 md:py-2 text-white">
                      <ArrowSmallRightIcon className="w-4 h-4 sm:w-6 sm:h-6 md:w-7 md:h-7" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Hero;

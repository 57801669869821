import React from "react";
import { Hero, Header, Products, Contact, Copyright } from "./../components";
import Elastics from "./../assets/images/Elastics.svg";
import Buttons from "./../assets/images/Buttons.svg";
import Cords from "./../assets/images/Cords.svg";
import Certificate from "./../assets/images/Certificate.jpg";
import { ArrowSmallRightIcon } from "@heroicons/react/24/outline";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { useNavigate } from "react-router-dom";

// Client (Brand) Images
import interloop from "./../assets/images/brands/interloop.png";
import target from "./../assets/images/brands/target.svg";
import jcpenney from "./../assets/images/brands/jcpenney.png";
import walmart from "./../assets/images/brands/walmart.svg";
import primark from "./../assets/images/brands/primark.svg";
import adidas from "./../assets/images/brands/adidas.svg";
import kmart from "./../assets/images/brands/kmart.svg";

const Home = () => {
  const navigate = useNavigate();

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <div
        className="static adsonsBreakpoint:absolute z-50 right-0 left-0"
        id="home"
      >
        <Header />
      </div>
      <div className="static adsonsBreakpoint:relative">
        <Hero />
        <div
          className="w-full adsonsBreakpoint:absolute adsonsBreakpoint:-bottom-28 adsonsBreakpoint:z-10 "
          // style={{ border: "5px solid red" }}
          style={{
            minHeight: "200px",
          }}
        >
          <div
            className="flex items-center max-w-adsonsWidth mx-auto bg-white flex-wrap md:flex-nowrap h-48 md:h-64"
            // style={{ height: "250px" }}
          >
            <div
              className="h-full w-full flex flex-col"
              // style={{ minWidth: "100px", minHeight: "200px", height: "250px" }}
            >
              <div className="flex-1 flex items-center justify-center pl-8 lg:pl-10 pr-14 lg:pr-20">
                <div className="text-2xl md:text-4xl text-[#0068E5] uppercase pb-5 border-b-8 border-b-[#A4A9D3]">
                  Quality at its best
                </div>
              </div>
              <div
                className="flex items-center cursor-pointer w-fit ml-auto"
                onClick={() => handleClickScroll("products")}
              >
                <div className="bg-gray-600 px-4 py-2 text-white uppercase md:text-lg">
                  Explore
                </div>
                <div className="px-3 bg-[#0068E5] py-2 text-white">
                  <ArrowSmallRightIcon className="w-6 h-6 md:w-7 md:h-7" />
                </div>
              </div>
            </div>
            <div className="bg-[#0068E5] h-full w-full flex items-center justify-center flex-col pb-3">
              <div className="w-36 mx-auto">
                <img src={Elastics} width="100%" height="100%" alt="Elastics" />
              </div>
              <div className="text-white text-2xl md:text-4xl uppercase text-center">
                Elastics
              </div>
            </div>
            <div
              className="bg-[#1A2692] h-full w-full flex items-center justify-center flex-col pb-3"
              // style={{ minWidth: "100px", minHeight: "200px", height: "250px" }}
            >
              <div className="w-36 mx-auto">
                <img src={Buttons} width="100%" height="100%" alt="Buttons" />
              </div>
              <div className="text-white text-2xl md:text-4xl uppercase text-center">
                Buttons
              </div>
            </div>
            <div
              className="bg-[#15216D] h-full w-full flex items-center justify-center flex-col pb-3"
              // style={{ minWidth: "100px", minHeight: "200px", height: "250px" }}
            >
              <div className="w-36 mx-auto">
                <img src={Cords} width="100%" height="100%" alt="Cords" />
              </div>
              <div className="text-white text-2xl md:text-4xl uppercase text-center">
                Cords
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Certificate */}
      <div
        className="flex items-center max-w-adsonsWidth mx-auto flex-wrap-reverse md:flex-nowrap gap-12 lg:mt-14 xl:mt-40 py-20 px-10 adsonsBreakpoint:px-0"
        id="about"
      >
        <div className="adsonsBreakpoint:text-4xl text-2xl md:w-7/12 leading-relaxed">
          ADSONS, established in 2017 has earned a name of reputation and
          quality in high performance elastics, fasteners, microfiber fabric and
          Yarns among quality conscious brands namely Adidas, Target and
          primark. The philosophy at ADSONS is to provide product solution not
          only the product.
        </div>
        <div className="md:w-5/12 sm:w-8/12 mx-auto mt-[550px] md:mt-0">
          <img src={Certificate} width="100%" height="100%" alt="Certificate" />
        </div>
      </div>
      {/* Happy Clients */}
      <div className="bg-white py-14">
        <div className="text-2xl sm:text-4xl uppercase text-center">
          Our happy clients
        </div>
        <div className="max-w-adsonsWidth mt-8 mx-7 adsonsBreakpoint:mx-auto">
          <Swiper
            spaceBetween={0}
            slidesPerView={"auto"}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
          >
            <SwiperSlide style={{ maxWidth: "fit-content" }}>
              <div className="sm:w-32 w-24 h-24 sm:h-32 mx-4 sm:mx-10 lg:mx-14 flex items-center justify-center">
                <img
                  src={interloop}
                  width="100%"
                  className="object-cover"
                  height="100%"
                  alt="Interloop"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide style={{ maxWidth: "fit-content" }}>
              <div className="sm:w-32 w-24 h-24 sm:h-32 mx-4 sm:mx-10 lg:mx-14 flex items-center justify-center">
                <img
                  src={target}
                  width="100%"
                  className="object-cover"
                  height="100%"
                  alt="Target"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide style={{ maxWidth: "fit-content" }}>
              <div className="sm:w-32 w-24 h-24 sm:h-32 mx-4 sm:mx-10 lg:mx-14 flex items-center justify-center">
                <img
                  src={jcpenney}
                  width="100%"
                  className="object-cover"
                  height="100%"
                  alt="JC Penney"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide style={{ maxWidth: "fit-content" }}>
              <div className="sm:w-32 w-24 h-24 sm:h-32 mx-4 sm:mx-10 lg:mx-14 flex items-center justify-center">
                <img
                  src={walmart}
                  width="100%"
                  className="object-cover"
                  height="100%"
                  alt="Walmart"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide style={{ maxWidth: "fit-content" }}>
              <div className="sm:w-32 w-24 h-24 sm:h-32 mx-4 sm:mx-10 lg:mx-14 flex items-center justify-center">
                <img
                  src={primark}
                  width="100%"
                  className="object-cover"
                  height="100%"
                  alt="Primark"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide style={{ maxWidth: "fit-content" }}>
              <div className="sm:w-32 w-24 h-24 sm:h-32 mx-4 sm:mx-10 lg:mx-14 flex items-center justify-center">
                <img
                  src={adidas}
                  width="100%"
                  className="object-cover"
                  height="100%"
                  alt="Adidas"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide style={{ maxWidth: "fit-content" }}>
              <div className="sm:w-32 w-24 h-24 sm:h-32 mx-4 sm:mx-10 lg:mx-14 flex items-center justify-center">
                <img
                  src={kmart}
                  width="100%"
                  className="object-cover"
                  height="100%"
                  alt="Kmart"
                />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      {/* Our Products */}
      <div className="bg-white" id="products">
        <Products />
      </div>
      {/* Contact */}
      <div id="contact">
        <Contact />
      </div>
      {/* Copyright */}
      <div>
        <Copyright />
      </div>
    </div>
  );
};

export default Home;

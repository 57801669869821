import React from "react";
import { Link } from "react-router-dom";

const Copyright = () => {
  return (
    <div
      className="bg-[#191A1C] text-white sm:text-lg py-5 px-5 text-center"
      style={{ letterSpacing: "2px" }}
    >
      Copyright @ {new Date().getFullYear()}{" "}
      <Link to="/" className="font-bold">
        Adsons Enterprises
      </Link>
      . All rights reserved. Developed by{" "}
      <Link to="https://iobatch.com/" target="blank" className="font-bold">
        iobatch
      </Link>
    </div>
  );
};

export default Copyright;

import React from "react";
import Book1 from "./../assets/images/products/Book1.jpg";
import Book2 from "./../assets/images/products/Book2.jpg";
import Book3 from "./../assets/images/products/Book3.jpg";
import Book4 from "./../assets/images/products/Book4.jpg";
import Book5 from "./../assets/images/products/Book5.jpg";
import Book6 from "./../assets/images/products/Book6.jpg";
import Book7 from "./../assets/images/products/Book7.jpg";
import Book8 from "./../assets/images/products/Book8.jpg";
import Book9 from "./../assets/images/products/Book9.jpg";
import Book10 from "./../assets/images/products/Book10.jpg";
import Book11 from "./../assets/images/products/Book11.jpg";
import Book12 from "./../assets/images/products/Book12.jpg";
import Book13 from "./../assets/images/products/Book13.jpg";
import Book14 from "./../assets/images/products/Book14.jpg";
import Book15 from "./../assets/images/products/Book15.jpg";
import Book16 from "./../assets/images/products/Book16.jpg";

const Products = () => {
  const products = [
    { img: Book1, title: "Jacquard Elastics" },
    { img: Book2, title: "Jacquard Elastics" },
    { img: Book3, title: "Jacquard Elastics" },
    { img: Book4, title: "Jacquard Elastics" },
    { img: Book5, title: "Jacquard Elastics" },
    { img: Book6, title: "Jacquard Elastics" },
    { img: Book7, title: "Jacquard Elastics" },
    { img: Book8, title: "Jacquard Elastics" },
    { img: Book9, title: "Jacquard Elastics" },
    { img: Book10, title: "Jacquard Elastics" },
    { img: Book11, title: "Jacquard Elastics" },
    { img: Book12, title: "Jacquard Elastics" },
    { img: Book13, title: "Undergarment Elastics" },
    { img: Book14, title: "Undergarment Elastics" },
    { img: Book15, title: "Undergarment Elastics" },
    { img: Book16, title: "Undergarment Elastics" },
  ];
  return (
    <div>
      <div className="text-2xl sm:text-4xl uppercase text-center">
        Our Products
      </div>
      <div className="mt-10 flex items-center justify-center flex-wrap">
        {products.map((product, index) => (
          <div key={index} className="w-6/12 md:w-3/12 relative">
            <img
              src={product.img}
              width="100%"
              className="object-cover aspect-square"
            />
            <div
              className="flex flex-col items-center justify-center text-white absolute z-10 bottom-0 w-full py-2 sm:py-3"
              style={{
                backgroundColor: "rgba(0, 0, 255, 0.6)",
              }}
            >
              <div
                className="text-lg sm:text-2xl font-semibold px-2"
                style={{ letterSpacing: "1px" }}
              >
                {product.title}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Products;

import React, { useState } from "react";
import logo from "./../assets/images/logo.jpg";
import { Link } from "react-router-dom";
import { Bars3Icon } from "@heroicons/react/24/outline";

const Header = () => {
  const navLinkClasses = [
    "px-4 lg:px-5 py-5 lg:py-6 text-lg lg:text-2xl uppercase hover:bg-[#0068E5] text-[#0068E5] hover:text-white cursor-pointer",
  ];

  const [isMobileNav, setIsMobileNav] = useState(false);

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <header className="bg-white flex items-center justify-between w-full mx-auto max-w-adsonsWidth adsonsBreakpoint:mt-3 relative">
        <div className="h-16 w-16 mx-3">
          <Link to="/">
            <img src={logo} alt="adsons logo" width="100%" height="100%" />
          </Link>
        </div>
        <div
          className="md:hidden px-6 py-5 cursor-pointer hover:bg-[#0068E5] text-[#0068E5] hover:text-white font-extrabold"
          onClick={() => setIsMobileNav(!isMobileNav)}
        >
          <Bars3Icon className="w-7 h-7" />
        </div>
        <nav className="hidden md:flex items-center">
          <div
            // to="/"
            onClick={() => handleClickScroll("home")}
            className={navLinkClasses}
            style={{ fontWeight: 600 }}
          >
            Home
          </div>
          <div
            // to="/about"
            onClick={() => handleClickScroll("about")}
            className={navLinkClasses}
            style={{ fontWeight: 600 }}
          >
            About
          </div>
          <div
            // to="/products"
            onClick={() => handleClickScroll("products")}
            className={navLinkClasses}
            style={{ fontWeight: 600 }}
          >
            Products
          </div>
          <div
            // to="/contact"
            onClick={() => handleClickScroll("contact")}
            className={navLinkClasses}
            style={{ fontWeight: 600 }}
          >
            Contact us
          </div>
        </nav>
        {/* Mobile Navbar */}
      </header>
      <nav className={`mobileNav ${isMobileNav ? "openMobileNav" : ""}`}>
        <div className="md:hidden flex flex-col items-center justify-center bg-[#0068E5] text-white w-full z-50">
          <div
            to="/"
            className="py-3 w-full text-center text-lg uppercase hover:bg-blue-800 focus:bg-blue-800 cursor-pointer"
            onClick={() => {
              setIsMobileNav(false);
              handleClickScroll("home");
            }}
            style={{ letterSpacing: "1.5px" }}
          >
            Home
          </div>
          <div
            // to="/about"
            className="py-3 w-full text-center text-lg uppercase hover:bg-blue-800 focus:bg-blue-800 cursor-pointer"
            onClick={() => {
              setIsMobileNav(false);
              handleClickScroll("about");
            }}
            style={{ letterSpacing: "1.5px" }}
          >
            About
          </div>
          <div
            // to="/products"
            className="py-3 w-full text-center text-lg uppercase hover:bg-blue-800 focus:bg-blue-800 cursor-pointer"
            onClick={() => {
              setIsMobileNav(false);
              handleClickScroll("products");
            }}
            style={{ letterSpacing: "1.5px" }}
          >
            Products
          </div>
          <div
            // to="/contact"
            className="py-3 w-full text-center text-lg uppercase hover:bg-blue-800 focus:bg-blue-800 cursor-pointer"
            onClick={() => {
              setIsMobileNav(false);
              handleClickScroll("contact");
            }}
            style={{ letterSpacing: "1.5px" }}
          >
            Contact us
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Layout, Home } from "./pages";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="*" element={<Home />} />
        {/* <Route element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="*" element={<Home />} />
        </Route> */}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
